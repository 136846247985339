<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex; margin: 2rem; justify-content: space-between">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Data Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="getRegion()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="period" class="form-label select-lable lable-text"> Period </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="period"
                            v-model="selectedPeriod" @change="getParameterDetails()" disabled>
                            <option v-for="wc in periodList" v-bind:key="wc.periodId" :value="wc.periodId">
                                {{ wc.periodName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="utility" class="form-label select-lable lable-text"> Region </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="regionId"
                            v-model="selectedRegionId" @change="regionChanged($event)">
                            <option v-for="region in regionList" v-bind:key="region.energyRegionId"
                                :value="region.energyRegionId">
                                {{ region.energyRegionName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            v-model="selectedPlantId" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.energyPlantId"
                                :value="plant.energyPlantId">
                                {{ plant.energyPlantName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-with-Plant default-font-color">
            <div class="button-dd">
                <div title="Export to Excel" v-if="energylossStatus.length !== 0" class="download-view action-btn"
                    @click="downloadList">
                    <img alt="download" src="@/assets/download_icon.svg" />
                </div>
            </div>
        </div>
        <div class="main-con" style="padding: 3rem 0 !important">
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-action-tracker">
                    <b-table id="submissionStatustbl" sticky-header class="project-list-table scroll gn-table-b" responsive
                        :items="energylossStatus" :fields="energylossStatusHeader" :per-page="perPage"
                        :current-page="currentPage">
                        <template #cell(submissionStatusId)="data">
                            <div style="display: flex; justify-content: center">
                                <button type="button" class="delete-view action-btn"
                                    v-if="data.item.status === 'DRAFT' || $oidc.userProfile.roles.includes(roles.POENERGYADMIN)"
                                    @click="editEnergyLoss(data.item.status, data.item.plantId, data.item.year)">
                                    <img alt="edit" src="../../assets/pencil.svg" />
                                </button>
                            </div>
                        </template>
                    </b-table>

                    <div v-if="energylossStatus && energylossStatus.length >= 1" class="pagination-con">
                        <div class="col-lg-3 col-md-12 col-sm-12 record-info">
                            {{ showingData(energylossStatus.length) }}
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 pagination-con">
                            <b-pagination class="pagination-align record-info" v-model="currentPage"
                                :total-rows="energylossStatus.length" :per-page="perPage" aria-controls="my-table"
                                prev-text="Back" next-text="Next" first-text="First" last-text="Last">
                            </b-pagination>
                        </div>
                        <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                            <div class="per-page-cont record-info">
                                <select v-model="perPage" class="per-page" aria-label="Default select example">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option v-if="energylossStatus.length > 3 && energylossStatus.length < 6"
                                        :value="energylossStatus.length">
                                        {{ energylossStatus.length }}
                                    </option>
                                </select>
                                Per page
                            </div>
                        </div>
                    </div>

                    <div style="justify-content: center; margin: 2rem 0" class="display-flex"
                        v-if="energylossStatus && energylossStatus.length === 0">
                        <div style="text-align: center">
                            <img alt="no data" src="@/assets/No_Data.svg" />
                            <div class="ed-date">No Data Found</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-con" style="padding-left: 16px; padding-top: 25px">
                <h4 style="color: darkblue">Following plants have not started the reporting for Energy losses for the year
                    {{ this.committedYr }}:</h4>
                <h4 style="color: blue">
                    <span style="text-decoration: underline; font-size: large; font-weight: bold">
                        {{ selectedRegionName + " Region" }}</span> : {{ showNwStPlants }}
                </h4>
            </div>
        </div>
    </div>
</template>
<script>
import "@/common/css/CommonStyle.css";
import "@/common/css/font-categories.css";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Loader from "@/components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "@/common/constants/api/index";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import Roles from "@/common/constants/roles";

export default {
    name: "EnergyLossStatus",
    data() {
        return {
            plantId: {},
            showLoader: true,
            plantList: [],
            plantList1: [],
            regionList: [],
            selectedPeriod: 8,
            selectedPlantId: 100,
            selectedRegionId: 10,
            selectedplnt: null,
            Submitted: true,
            committedYear: [],
            committedYr: new Date().getFullYear(),
            periodList: [],
            currentPage: 1,
            perPage: 5,
            energylossStatus: [],
            energylossStatusHeader: [
                {
                    label: "Year",
                    key: "year",
                    sortable: false
                },
                // {
                //     label: "Period",
                //     key: "period",
                //     sortable: false
                // },
                {
                    label: "Region",
                    key: "regionName",
                    sortable: false
                },
                {
                    label: "Plant",
                    key: "plantName",
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Status",
                    key: "status",
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "User Name",
                    key: "userName",
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Edit",
                    key: "submissionStatusId",
                    sortable: false,
                    variant: "success"
                }
            ],
            QId: [],
            RegId: [],
            PlntId: [],
            selectedRegionName: "All",
            selectedPlantName: "All",
            newStatusPlant: [],
            showNwStPlants: "",
            roles: Roles,
            showEditBtn: true
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 5,
            subMenu: 4
        });
        this.$store.dispatch("breadcrumb", {
            title: "Energy Loss Submission Status",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Energy Loss Submission Status", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected", "prevSelectedRegion"]),
        titleWithPlant() {
            return this.plantId.hasOwnProperty("plantName") ? `Energy Loss for ${this.plantId.plantName ?? " "}, ${this.plantId.regionCode ?? " "}` : " ";
        }
    },
    methods: {
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.GETENERGYCALCULATORYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPeriod();
            });
        },
        getPeriod() {
            ApiCalls.httpCallDetails(Api.GETPERIOD, "get").then((data) => {
                this.periodList = data.data.filter((r) => r.periodId == 8);
                console.log("periodList", this.periodList);

                this.selectedPeriod = 8;
                this.getRegion();
            });
        },
        getRegion() {
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSREGIONS, "get").then((data) => {
                this.regionList = data.data;
                this.regionList.unshift({
                    energyRegionId: 10, // ALL 10-Unique No
                    energyRegionName: "All"
                });
                let rId = this.selectedRegionId;
                if (rId == 10) {
                    this.RegId = [];
                    for (let i = 1; i < this.regionList.length; i++) {
                        this.RegId.push(this.regionList[i].energyRegionId);
                    }
                } else {
                    this.RegId = [];
                    this.RegId.push(this.selectedRegionId);
                }

                this.getNewStatusPalnt();
                this.getPlantList(this.RegId);
            });
        },
        regionChanged(event) {
            this.showLoader = true;
            this.RegId = [];
            if (event.target.value == 10) {
                for (let i = 1; i < this.regionList.length; i++) {
                    this.RegId.push(this.regionList[i].energyRegionId);
                }
            } else {
                this.RegId.push(event.target.value);
            }
            this.selectedRegionName = this.regionList.find((el) => el.energyRegionId == event.target.value).energyRegionName;
            this.getNewStatusPalnt();
            this.getPlantList(this.RegId);
        },
        getPlantList(RegId) {
            let plantPayload = {
                regionIds: RegId,
                year: this.committedYr
            };
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSPLANTS, "post", plantPayload).then((data) => {
                if (RegId.length == 1) {
                    this.plantList = data.data.filter(function (el) {
                        return el.energyRegionId == RegId;
                    });
                    this.plantList.unshift({
                        energyPlantId: 100, // ALL 100-Unique No
                        energyPlantName: "All"
                    });
                    this.selectedPlantId = 100;
                } else {
                    this.plantList = data.data;
                    this.plantList.unshift({
                        energyPlantId: 100, // ALL 100-Unique No
                        energyPlantName: "All"
                    });
                    this.selectedPlantId = 100;
                }
                this.plantId.energyPlantId = 100;

                // this.selectedPlantId = data.data[0].energyPlantId;
                // this.plantId = this.selectedPlantId
                // this.filterHandler();
                this.showLoader = false;
                this.filterHandler();
            });
        },
        plantChanged(event) {
            this.showLoader = true;
            this.plantId = this.plantList.find((el) => el.energyPlantId == event.target.value);
            this.selectedPlantName = this.plantList.find((el) => el.energyPlantId == event.target.value).energyPlantName;
            this.filterHandler();
        },
        showingData(count) {
            let showData = this.perPage * this.currentPage;
            let showFirstData = showData - this.perPage;
            if (showData > count) {
                showData = count;
            }
            showFirstData = showFirstData + 1;
            return "Showing " + showFirstData + " to " + showData + " of " + count + " records";
        },
        // plantChanged(event) {
        //     console.log("plantChanged");
        //     // let selectedplnt= event.target.value;
        //     this.showLoader = true;
        //     this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
        //     this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
        //     this.$store.dispatch("prevSelectedWorkCenter", 0);
        //     this.filterHandler();
        // },
        // regionChanged(event) {
        //     this.showLoader = true;
        //     // this.$store.dispatch("prevSelectedRegion", event.target.value);
        //     // console.log("prevSelectedRegion", this.prevSelectedRegion)
        //     // this.$store.dispatch("plantSelectedInAsmtHome", 0);
        //     //(this.prevSelectedRegion);
        //     this.RegId=[]
        //     this.RegId.push(event.target.value);
        //     this.getPlantList(this.RegId)
        //     // this.filterHandler();

        // },
        filterHandler() {
            if (this.selectedRegionId == 10 && this.plantId.energyPlantId == 100) {
                this.PlntId = [];
                for (let i = 1; i < this.plantList.length; i++) {
                    this.PlntId.push(this.plantList[i].energyPlantId);
                }
            } else if (this.selectedRegionId == 1 && this.plantId.energyPlantId == 100) {
                // AP
                this.PlntId = [];
                this.tempAP = this.plantList.filter(function (el) {
                    return el.energyRegionId == 1;
                });
                for (const element of this.tempAP) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 2 && this.plantId.energyPlantId == 100) {
                // CHEM

                this.PlntId = [];
                this.tempCHEM = this.plantList.filter(function (el) {
                    return el.energyRegionId == 2;
                });
                for (const element of this.tempCHEM) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 3 && this.plantId.energyPlantId == 100) {
                // EMEA


                this.PlntId = [];
                this.tempEMEA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 3;
                });
                for (const element of this.tempEMEA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 4 && this.plantId.energyPlantId == 100) {
                // GLOBAL


                this.PlntId = [];
                this.tempGLOBAL = this.plantList.filter(function (el) {
                    return el.energyRegionId == 4;
                });
                for (const element of this.tempGLOBAL) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 5 && this.plantId.energyPlantId == 100) {
                // LA


                this.PlntId = [];
                this.tempLA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 5;
                });
                for (const element of this.tempLA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 6 && this.plantId.energyPlantId == 100) {
                // NA


                this.PlntId = [];
                this.tempNA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 6;
                });
                for (const element of this.tempNA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else {
                this.PlntId = [];
                this.PlntId.push(this.plantId.energyPlantId);
            }

            let statusPayload = {
                year: [this.committedYr],
                // periodId: [1],
                // regionId: this.RegId,
                plantId: this.PlntId
            };
            console.log("statusPayload", statusPayload);
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.GETSUBMISSIONSTATUS, "post", statusPayload).then((data) => {
                this.energylossStatus = data.data;
                this.showLoader = false;
                console.log("GETSUBMISSIONSTATUS", this.energylossStatus);
            });
        },
        editEnergyLoss(ev, stPlantId, styear) {
            let status = ev;
            let statusPlantId = stPlantId;
            let statusYear = styear;

            if (this.$oidc.userProfile.roles.includes(this.roles.POENERGYADMIN)) {
                this.$router.push({ path: "/energy-loss-calculator", query: { stPlantId: statusPlantId, styear: statusYear } });
            } else {
                if (status == "DRAFT") {
                    this.$router.push({ path: "/energy-loss-calculator", query: { stPlantId: statusPlantId, styear: statusYear } });
                }
            }
        },
        downloadRawData() {
            console.log("downloadRawData");
        },
        downloadList() {

            let exportExl = [];
            this.energylossStatus.forEach((e) => {
                let jsonSheet = {};
                jsonSheet["Year"] = e.year;
                jsonSheet["Region"] = e.regionName;
                jsonSheet["Plant"] = e.plantName;
                jsonSheet["Status"] = e.status;
                jsonSheet["User Name"] = e.userName;
                exportExl.push(jsonSheet);
            });
            let sheet = XLSX.utils.json_to_sheet(exportExl);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Energy Loss Submission Status");
            XLSX.writeFile(wb, "Energy Loss Submission Status.xlsx");
        },
        getNewStatusPalnt() {
            let nwPlayLoad = {
                year: this.committedYr,
                regionIds: this.RegId
            };
            ApiCalls.httpCallDetails(Api.NEWSTATUSPLANT, "post", nwPlayLoad).then((data) => {
                this.newStatusPlant = data.data;
                console.log("newStatusPlant", data);
                let newPlants = "";
                this.newStatusPlant.forEach((NewPara) => {
                    newPlants += NewPara.energyPlantName + ", ";
                });
                newPlants = newPlants.substring(0, newPlants.length - 1);
                this.showNwStPlants = `${newPlants.length ? "\n\n" : ""}  ${newPlants.substring(0, newPlants.length - 1)}`;
                console.log("NEWSTATUSPLANT", this.newStatusPlant);
                console.log("this.showNwStPlants", this.showNwStPlants);
            });
        }
    }
};
</script>
<style scoped>
/* new css */
.machine-action-tracker {
    margin-top: 0.5rem;
    width: 100%;
}

.pencilicon {
    background-color: #274ba7;
    -webkit-mask: url(../../assets/pencil.svg) no-repeat 50% 50%;
    mask: url(../../assets/pencil.svg) no-repeat 50% 50%;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.machine-action-table {
    height: 100%;
    width: 100%;
    /* padding-left: 3rem; */
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
    border: 2px solid #efefef !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
    min-width: 40px;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th {
    width: 21.66%;
}

/* .machine-action-table th:first-child,
.machine-action-table td:first-child {
    width: 35%;
    background-color: transparent !important;
    text-align: left !important;
} */

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 3rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.title-with-Plant {
    font-size: 2.2rem;
    font-family: "BarlowSB", sans-serif;
    display: flex;
    justify-content: flex-end;
}

.input-field-container {
    width: 100%;
    display: flex;
    margin: 1rem;
    justify-content: space-between;
}

.parameter-name {
    color: #5c5c5c;
    font: 1.16rem "BarlowM", sans-serif;
    margin: 1rem;
}

.input-field-div {
    display: flex;
    flex-direction: column;
    width: 22%;
}

.input-field-container div label,
.input-field-div label {
    color: #4161b2;
    font: 1.16rem "BarlowM", sans-serif;
}

.card-sub-container,
.card-container {
    border: 1px solid #d6dff833;
}

.card-container:nth-child(even) {
    background: #f7f9ff;
}

.sub-card {
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
}

.sub-card label {
    font: 1.16rem "BarlowR", sans-serif;
    margin-right: 1rem;
}

.sub-card span {
    font: 1.16rem "BarlowM", sans-serif;
}

/* new css */
/* tabs */

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */
.plant-dd {
    width: 20.5%;
}

.main-con {
    background: #fff;
    margin-top: 0em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.reload-img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

div.fileinputs {
    position: relative;
    border: 1px solid #313131;
    border-radius: 4px;
    width: 100%;
}

div.fakefile {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fakefileinput {
    height: 100%;
    width: 80%;
    border: transparent;
    border-right: 1px solid #313131;
    color: #313131;
    font: 1.166rem "BarlowR", sans-serif;
    padding: 5px;
    margin: 2px;
}

.fakefilespan {
    text-align: center;
    width: 20%;
    font: 1.166rem "BarlowSb", sans-serif;
    color: #274ba7;
}

input.file {
    position: relative;
    text-align: right;
    width: 100%;
    /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
    opacity: 0;
    z-index: 2;
}

.action-info-block {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.no-padding {
    padding: 0;
}

.lable-text {
    z-index: 2;
}

.upload-button-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.pagination-con {
    display: flex;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.action-desc {
    border: #313131 1px solid;
    border-radius: 4px;
    padding: 1rem;
    width: 90%;
    resize: none;
}

.calender-input {
    height: 52px;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* document.querySelector("#history-table-id > thead > tr > th:nth-child(1)") */
#history-table-id {
    border: 0px solid white !important;
    border-top: 1px solid #707070 !important;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1365px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1921px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}
</style>
